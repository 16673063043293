import axios from "@axios";

export default {
    namespaced: true,
    state: {
        listsDevice: [],
    },
    getters: {
        getListsDevice: (state) => state.listsDevice,
    },
    mutations: {
        SET_LISTS_DEVICE(state, value) {
            state.listsDevice = value;
        },
    },
    actions: {
        getDevice(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/devices")
                    .then((response) => {
                        // console.log(response);
                        resolve(response);
                        ctx.commit("SET_LISTS_DEVICE", response.data.data);
                    })
                    .catch((error) => reject(error));
            });
        },
        postDevice(ctx, name) {
            // console.log(name)
            return new Promise((resolve, reject) => {
                axios
                    .post("/devices", { name })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        putDevice(ctx, { id, name }) {
            // console.log(id, name);
            return new Promise((resolve, reject) => {
                axios
                    .put(`/devices/${id}`, { name })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteDevice(ctx, { id }) {
            // console.log(id, name);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/devices/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
