<template>
    <b-card no-body class="overflow-hidden">
        <b-row class="px-2 py-1" align-v="center">
            <b-col cols="auto" class="mr-auto">
                <h4>Device SmartFarm</h4>
            </b-col>

            <b-col cols="auto" class="">
                <b-button variant="primary" v-b-toggle.sidebar-add>
                    <feather-icon icon="PlusIcon" />
                    <span>Add Record</span>
                </b-button>
                <b-sidebar
                    id="sidebar-add"
                    right
                    shadow
                    bg-variant="white"
                    title="Add New Animal Status"
                >
                    <div class="p-2">
                        <device-add-new />
                    </div>
                </b-sidebar>
            </b-col>
        </b-row>
        <hr />
        <div class="px-2 py-1">
            <b-col cols="12" md="3" class="">
                <b-form-input
                    v-model="searchQueryRawMilkSummary"
                    class="d-inline-block"
                    placeholder="Search..."
                />
            </b-col>
        </div>
        <div>
            <b-table responsive="sm" :items="listsDevice" :fields="fields">
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item
                            :to="{
                                name: 'milkProductDetail',
                                params: { id: data.item.id },
                            }"
                        >
                            <feather-icon icon="FileTextIcon" />
                            <span class="align-middle ml-50"
                                >Detail Pencatatan</span
                            >
                        </b-dropdown-item>

                        <b-dropdown-item
                            v-b-toggle.sidebar-edit
                            @click="getDevice(data.item)"
                        >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteDevice(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
        </div>
        <b-sidebar
            id="sidebar-edit"
            right
            shadow
            bg-variant="white"
            title="Edit Device"
        >
            <div class="p-2">
                <device-edit :AnimalStatus="device" />
            </div>
        </b-sidebar>
    </b-card>
</template>

<script>
import {
    BButton,
    BSidebar,
    VBToggle,
    BTable,
    BCard,
    BAvatar,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { mapGetters } from "vuex";
import DeviceAddNew from "./DeviceAddNew.vue";
import DeviceEdit from "./DeviceEdit.vue";
import masterDeviceStoreModule from "./masterDeviceStoreModule";
import { onUnmounted } from "@vue/composition-api";

export default {
    components: {
        BButton,
        BSidebar,
        BTable,
        BCard,
        BAvatar,
        BRow,
        BCol,
        BFormInput,
        BDropdown,
        BDropdownItem,

        DeviceAddNew,
        DeviceEdit,
    },
    setup() {
        const DEVICE_STORE_MODULE_NAME = "app-device";

        // Register module
        if (!store.hasModule(DEVICE_STORE_MODULE_NAME))
            store.registerModule(
                DEVICE_STORE_MODULE_NAME,
                masterDeviceStoreModule
            );

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(DEVICE_STORE_MODULE_NAME))
                store.unregisterModule(DEVICE_STORE_MODULE_NAME);
        });
    },
    directives: {
        "b-toggle": VBToggle,
    },
    data() {
        return {
            fields: ["id", "name", "actions"],
            device: {},
        };
    },
    computed: {
        ...mapGetters({
            listsDevice: "app-device/getListsDevice",
        }),
    },
    created() {
        store.dispatch("app-device/getDevice");
    },
    methods: {
        getDevice(item) {
            // console.log(name);
            this.device = item;
        },
        deleteDevice(id) {
            store
                .dispatch("app-device/deleteDevice", { id })
                .then((response) => {
                    store.dispatch("app-device/getDevice");
                })
                .catch((err) => console.log(err.response));
        },
    },
};
</script>
