<template>
    <!-- form -->
    <validation-observer ref="simpleRules">
        <b-form ref="formDevice">
            <b-form-group label="Nama">
                <validation-provider
                    #default="{ errors }"
                    name="nama"
                    rules="required"
                >
                    <b-form-input
                        v-model="name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Nama"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
            <!-- <div>{{ id }}</div> -->

            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
                Submit
            </b-button>
        </b-form>
    </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
} from "bootstrap-vue";
import store from "@/store";
import { required } from "@validations";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
    },
    props: {
        device: Object,
    },
    data() {
        return {
            id: 0,
            name: "",
            required,
        };
    },
    watch: {
        device(value) {
            //   console.log(value);
            this.id = this.$props.device.id;
            this.name = this.$props.device.name;
        },
    },
    methods: {
        validationForm() {
            // console.log(this.$refs);
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    // eslint-disable-next-line
                    alert("form submitted!");
                    store
                        .dispatch("app-device/putDevice", {
                            id: this.id,
                            name: this.name,
                        })
                        .then((response) => {
                            store.dispatch("app-device/getDevice");
                        })
                        .catch((err) => console.log(err.response));
                }
            });
        },
    },
};
</script>
